var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.slide
    ? _c(
        "div",
        { staticClass: "resting-slide-container" },
        [
          _c("slide-heading", { attrs: { "html-heading": _vm.slide.heading } }),
          _vm.slide.image_urls.length && _vm.slide.description
            ? _c(
                "div",
                [
                  _c("slide-image-and-description", {
                    attrs: { slide: _vm.slide },
                  }),
                ],
                1
              )
            : _vm.slide.description
            ? _c("div", [
                _c("div", { staticClass: "description" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                  }),
                ]),
              ])
            : _vm.slide.image_urls.length
            ? _c("div", [
                _c("div", { staticClass: "image-vert" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.slide.image_alt_text },
                  }),
                ]),
              ])
            : _vm._e(),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [_c("b-spinner", { attrs: { variant: "primary", large: "" } })],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }